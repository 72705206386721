var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3" } },
    [
      _c(
        "vx-card",
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start mb-2" },
            [
              _c("h2", { staticStyle: { color: "#636363" } }, [
                _vm._v(_vm._s(_vm.groupName)),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "ml-3",
                  attrs: { size: "small", type: "border", color: "success" },
                  on: { click: _vm.editGroupName },
                },
                [_vm._v("Edit")]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "text-muted mb-4" }, [
            _vm._v(_vm._s(_vm.groupDescription)),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: { click: _vm.addExtra },
                  },
                  [_vm._v("\n          Add Extra\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "sm:mb-0 mb-2",
                    attrs: { type: "border" },
                    on: { click: _vm.onCancel },
                  },
                  [_vm._v("Back To Group List")]
                ),
                _c("vs-input", {
                  staticClass:
                    "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                  attrs: { placeholder: "Search..." },
                  on: { input: _vm.updateSearchQuery },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("h5", { staticClass: "text-muted mt-6 mb-2" }, [
            _vm._v("Extra List"),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 mb-4 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.extraList,
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              rowSelection: "single",
              pagination: true,
              context: _vm.context,
              suppressPaginationPanel: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }